<template>
    <div class="inline-block aspect-[16/9] bg-slate-700 rounded-t-md demo-screen screen-00"></div>
</template>

<style scoped lang="scss">
    .demo-screen {
        @apply bg-cover bg-no-repeat;
        background-position-y: 18px;
    }
    .screen-00 {
        background-image: url("@/assets/visual/screenshot/screen-00.png");
    }
</style>