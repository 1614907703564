<template>
    <div class="py-2">
        <div class="container mx-auto flex flex-row items-center">
            <div class="shrink">
                <!-- <img src="@/assets/visual/logo/kale-kilit.svg" class="w-20" /> -->
            </div>
            <nav class="grow flex flex-row items-center justify-center">
                <!-- -->
            </nav>
        </div>
    </div>
</template>