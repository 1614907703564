<template>
    <div class="flex flex-row gap-8 text-sm xl:text-base">
        <div class="basis-1/4 text-right">
            <h2 class="font-bold text-xl xl:text-3xl">Sizin için en uygun senaryoyu seçin</h2>
        </div>

        <div class="basis-1/4">
            <ul class="flex flex-col gap-8">
                <li v-for="(o,$index) in downloadOptions" :key="$index" class="p-2 cursor-pointer transition-all duration-200" :class="downloadOption != $index ? 'opacity-50 hover:opacity-100' : 'bg-red-700/5 text-red-700 border border-red-700 rounded-lg'" @click="downloadOption = $index">
                    <h3 class="font-bold">
                        <fa-icon :icon="`fa-regular ${downloadOption == $index ? 'fa-circle-check': 'fa-circle'}`" />
                        {{o.title}}
                    </h3>
                    <p class="pl-5" v-html="o.text"></p>
                </li>
            </ul>
        </div>

        <div class="basis-2/4 installation-directives">
            <div v-if="showHotelSystemDownload">
                <h3 class="text-xl"><strong>Hotel System</strong> indirin ve kurun.</h3>
                <p>
                    Kale Kilit <strong>Hotel System</strong> giriş noktalarını güvenle yönetebilmeniz 
                    için gerekli olan temel yazılımdır. Yapınızı kolayca kurmanızı ve yönetmenizi sağlar. 
                </p>

                <p v-if="showHotelServerDownload">
                    Ayrıca ağ üzerinden gelen talepleri karşılar ve tek noktadan yönetime izin verir.
                    Bunun için ağ üzerinde <strong>Sabit IP</strong> ile yapılandırılmış tek bir bilgisayara 
                    <strong>Hotel System</strong> kurun. Diğer bilgisayarları ise <strong>Hotel Client</strong>
                    ile yapılandırın.
                </p>

                <p v-if="showHotelServerDownload" class="text-warning">
                    <fa-icon icon="fa fa-warning" />
                    <strong>Hotel System</strong> kurulacak bilgisayar için <strong>sabit IP adresi</strong> tanımlanmış olmalı 
                    ve bilgisayarın ağ üzerinden erişim için yapılandırılmış olması gerekir. 
                    Konu hakkında IT sorumlusundan destek isteyin.
                </p>

            </div>

            <div v-else-if="downloadOption == 'client'">
                <h4 class="text-xl"><strong>Hotel Client</strong> indirin ve kurun.</h4>

                <p>
                    Kale Kilit <strong>Hotel Client</strong> temel hizmetlere (Hotel System) kolayca erişmenizi ve kullanmanızı sağlar.
                </p>

                <p>Kullanmak istediğiniz bilgisayara <strong>Hotel Client</strong> kurun ve daha önce <strong>Hotel System</strong> kurduğunuz 
                    bilgisayarın IP adresi ile yapılandırmayı tamamlayın.</p>
                </div>

            <div class="flex flex-row gap-4">
                <download-hotel-system v-if="showHotelSystemDownload" />
                <download-hotel-client v-if="showHotelClientDownload" />
            </div>
        </div>
    </div>
</template>

<script setup>
import {ref, computed} from "vue";
import DownloadHotelSystem from "@/components/download-hotel-system.vue";
import DownloadHotelClient from "@/components/download-hotel-client.vue";

const downloadOption = ref("single");
const downloadOptions = ref({
    single: {
        title: "Tek bilgisayar ile kullanım...",
        text:"Tüm işlemleri tek bir bilgisayardan yönetin."
    },
    simple: {
        title: "Basit çalışma ağı kurun...",
        text: "İki veya daha fazla bilgisayar için basit yerel ağ kurulumu yapın.",
    },
    /*company: {
        title: "Kurumsal bir ağın parçası...",
        text:"Bir sunucuyu ve bilgisayarları tek noktadan hizmet verecek şekilde yapılandırın."
    },*/
    client: {
        title: "Yeni uç nokta ekleyin...",
        text: "Kurulu sisteme yeni bir erişim noktası ekleyin."
    }
});

const showHotelSystemDownload = computed(()=> ["single", "simple", "company"].indexOf(downloadOption.value) >= 0);
const showHotelClientDownload = computed(()=> ["simple", "company", "client"].indexOf(downloadOption.value) >= 0);
const showHotelServerDownload = computed(()=> ["simple", "company"].indexOf(downloadOption.value) >= 0);

</script>

<style lang="scss">
.installation-directives {
    p {
        @apply my-3;
    }
}
</style>