<template>
    <a :href="link" download="Hotel-System-Setup" class="inline-flex gap-2 items-center bg-amber-400 py-2 px-4 rounded transition-all duration-200 shadow shadow-slate-400 hover:bg-amber-300 hover:shadow-lg">
        <fa-icon :icon="loading ? 'fa-spinner' : 'fa-hotel'" :spin="loading" />
        <strong>Hotel System</strong> <em>({{size.toFixed(1)}} MB)</em>
    </a>
</template>

<script setup>
import { ref } from 'vue';

const link = ref("javascript:;");
const size = ref(0);
const loading = ref(true);

fetch('https://license.kalekilit.online/api/version-info/hotel-system')
        .then(async (data)=> {
            const {download_url, download_bytes} = await data.json();

            link.value = download_url;
            size.value = Number(download_bytes || 0) / (1024 * 1024);
        })
        .finally(()=> loading.value = false);

</script>