<template>
    <div>
            <h2 class="font-bold text-4xl text-center whitespace-nowrap text-white">
                Yardım Belgeleri
            </h2>

            <div class="flex flex-row gap-8 mx-auto mt-10 max-w-2xl">
                <div class="grow text-white opacity-75 ">
                    <p>
                        Kurulum, yapılandırma yönergeleri ve uygulamanın detaylı kullanımı detaylı bilgi almak için yardım
                        belgelerini indirin.
                    </p>
                </div>

                <div class="shrink">
                    <a href="javascript:;"
                        class="inline-flex gap-2 items-center bg-amber-400 py-3 px-8 rounded transition-all duration-200 shadow shadow-black hover:bg-amber-300 hover:shadow-lg">
                        <fa-icon icon="fa-solid fa-file-pdf" />
                        <strong>İNDİR</strong>
                    </a>
                </div>
            </div>
        </div>

</template>