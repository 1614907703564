import { createApp } from 'vue'
import App from './App.vue'

import { faCloudDownload, faHotel, faDisplay, faServer, faCloudUpload, faMagnifyingGlassChart, faWarning, faFilePdf, faSquarePhone, faLocationDot, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { faCircle, faCircleCheck } from "@fortawesome/free-regular-svg-icons";
import { faBluetoothB } from '@fortawesome/free-brands-svg-icons';

import { library } from '@fortawesome/fontawesome-svg-core';
library.add([
    faCloudDownload,
    faHotel,
    faDisplay,
    faServer,
    faCloudUpload,
    faMagnifyingGlassChart,
    faWarning,
    faFilePdf,
    faSquarePhone,
    faLocationDot,
    faSpinner,

    faCircle,
    faCircleCheck,

    faBluetoothB
]);

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

createApp(App)
    .component('faIcon', FontAwesomeIcon)
    .mount('#app');
