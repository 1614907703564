<template>
    <div class="inline-flex flex-row flex-nowrap items-center justify-between gap-2">
        <div class="basis-1/5"><img src="@/assets/visual/logo/kale-kilit.svg" class="aspect-square w-full" /></div>
        <div class="basis-4/5">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 370.32 47.62" class="w-full">
                <path
                    d="m0,47.62V0h12.57v47.62H0Zm15.09-28.7L27.9,0h14.36l-15.82,23.41,16.3,24.2h-14.44l-13.05-19.18c-1.57-2.3-2.35-3.92-2.35-4.9,0-.71.24-1.55.73-2.51l1.46-2.12Z" />
                <path
                    d="m44.55,47.62L60.51,6.28c1.6-4.19,4.87-6.28,9.78-6.28s8.1,2.09,9.7,6.28l15.96,41.34h-12.99l-3.93-10.2h-17.57l-3.93,10.2h-12.99Zm20.61-19.75h10.19l-5.85-15.31-4.33,15.31Z" />
                <path
                    d="m96.49,35.97V0h12.57v35.19c0,1.41,1.05,2.12,3.16,2.12h22.23l-.08,10.31h-23.28c-.75,0-1.46-.04-2.11-.13-4.44-.26-7.62-1.28-9.57-3.04-1.95-1.85-2.92-4.68-2.92-8.47Z" />
                <path
                    d="m139.24,37.7V10.31c0-.62.08-1.32.24-2.11,1.08-5.47,5.06-8.2,11.92-8.2h25.96v10.18h-22.63c-.16.09-.43.18-.81.27-1.41.44-2.11,1.36-2.11,2.77v5.29h25.55v10.32h-25.55v5.29c0,1.94,1.08,3,3.25,3.17h22.31v10.31h-24.17c-9.3,0-13.95-3.3-13.95-9.92Z" />
                <path
                    d="m207.17,47.62V0h12.58v47.62h-12.58Zm15.08-28.7L235.07,0h14.36l-15.82,23.41,16.3,24.2h-14.43l-13.06-19.18c-1.56-2.3-2.35-3.92-2.35-4.9,0-.71.25-1.55.73-2.51l1.46-2.12Z" />
                <path
                    d="m272.85,35.97V0h12.57v35.19c0,1.41,1.05,2.12,3.16,2.12h22.22l-.07,10.31h-23.28c-.76,0-1.46-.04-2.11-.13-4.43-.26-7.62-1.28-9.57-3.04-1.95-1.85-2.92-4.68-2.92-8.47Z" />
                <polygon
                    points="332.69 10.18 332.69 0 370.32 0 370.32 10.18 357.75 10.18 357.75 47.62 345.17 47.62 345.17 10.18 332.69 10.18" />
                <rect x="252.88" y="6.95" width="12.53" height="40.67" />
                <rect x="252.88" width="12.53" height="5.12" />
                <rect x="315.09" y="6.95" width="12.52" height="40.67" />
                <rect x="315.09" width="12.52" height="5.12" />
            </svg>
        </div>
    </div>
</template>
