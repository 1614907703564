<template>
    <div class="container mx-auto max-w-4xl grid grid-cols-4 gap-4 items-stretch justify-around my-16">
        <FeatureBadge v-for="(f, $index) in features" :key="$index" :icon="f.icon" :title="f.title">
            {{ f.text }}
        </FeatureBadge>
    </div>
</template>

<script setup>
import { ref } from "vue";
import FeatureBadge from "@/components/feature-badge.vue";

const features = ref([
    { icon: "fa-solid fa-display", title: "Basit Arayüz", text: "Basit arayüzü ile ilk andan itibaren dikkatiniz dağılmadan kolayca kullanın." },
    { icon: "fa-solid fa-magnifying-glass-chart", title: "Detaylı Raporlama", text: "Tüm giriş noktalarından ayrıntılı kayıtlar alın, filtreleyin ve aratın." },
    { icon: "fa-solid fa-cloud-upload", title: "Bulut Yedekleme", text: "Bulut yedekleme hizmeti ile son yedeğinizi her zaman güvende tutun." },
    { icon: "fa-brands fa-bluetooth-b", title: "Bluetooth Desteği", text: "Bluetooth desteği ile tek tuşla izin verin, engeleyin ve raporlayın." },
])
</script>